<script setup lang="ts">
const relationshipsStore = useRelationshipsStore();
const web3Store = useWeb3Store();
const balance = ref<number>(0);
const showPopper = ref<boolean>(false);
const route = useRoute();
const userStore = useUserStore();

onMounted(async () => {
  const workspace = relationshipsStore.workspaces.find(
    (relationship) => relationship.nanoid === route.params.workspaceId
  );
  if (workspace) {
    if (
      ["contractor"].includes(workspace.type) &&
      userStore.me?.user?.rise_accounts
    ) {
      balance.value = await web3Store.getBalance(
        userStore.me.user.rise_accounts[0] as string
      );
    } else balance.value = await web3Store.getBalance(workspace.rise_account);
  }
});
</script>

<template>
  <div class="rounded-lg px-3 py-4 bg-gradient-20">
    <VDropdown
      v-if="relationshipsStore.workspaces"
      :distance="6"
      :shown="showPopper"
      placement="right-end"
    >
      <div
        class="relative flex cursor-pointer items-center gap-2 rounded-lg bg-white/10 px-3 py-[7.5px] text-white backdrop-blur-[2px] transition hover:bg-white/20"
        @click="
          relationshipsStore.relationshipsIncreased = false;
          showPopper = !showPopper;
        "
      >
        <div
          class="absolute left-0 top-0 h-full w-full rounded-lg border border-white/20"
        ></div>
        <div class="flex-1">
          <div
            v-if="relationshipsStore.currentWorkspace"
            class="mb-1 flex items-center gap-1 text-[10px] font-bold uppercase text-gray-300/70"
          >
            <span>{{ relationshipsStore.currentWorkspace.team }}</span>
            <font-awesome-icon icon="info-circle" />
          </div>
          <div class="text-xs font-medium leading-[14px]">
            {{
              relationshipsStore.currentWorkspace
                ? relationshipsStore.roleMap[
                    relationshipsStore.currentWorkspace.type
                  ]
                : userStore.me.user
                ? `${userStore.me.user.first_name} ${userStore.me.user.last_name}`
                : "Loading..."
            }}
          </div>
        </div>
        <div class="flex items-center">
          <i class="ri-fw ri-expand-up-down-fill" />
        </div>
      </div>

      <!-- This will be the content of the popover -->
      <template #popper>
        <Teleport to="body">
          <div
            class="fixed top-0 right-0 h-screen w-[calc(100%-220px)] backdrop-blur-md z-10"
          ></div>
        </Teleport>
        <div
          class="relative min-w-[500px] rounded-3xl bg-gray-100 bg-opacity-90 dark:bg-black dark:bg-opacity-70 dark:backdrop-blur-lg backdrop-blur-3xl font-medium dark:text-white overflow-hidden py-3 ml-6 popup-overlay mt-[-50px] border border-gray-300 dark:border-gray-900 text-black"
          style="backdrop-filter: blur(10px)"
        >
          <div
            v-for="workspace in relationshipsStore.workspacesNavigation"
            :key="workspace.nanoid"
            class="mb-2"
          >
            <div
              class="px-4 pt-3 text-sm font-normal text-[#6B7280] dark:text-[#D1D5DB]"
            >
              My Profile
            </div>
            <LeftMenuCardWorkspaceItem
              id="0"
              name="My Profile"
              role="user"
              :tasks-number="0"
              :is-profile="true"
              class="my-3"
              @click="showPopper = !showPopper"
            />
            <div
              class="px-4 text-sm font-normal text-[#6B7280] border-t border-gray-400 dark:border-gray-800 pt-4 dark:text-[#D1D5DB]"
            >
              {{ workspace.company_name }}
            </div>
            <LeftMenuCardWorkspaceItem
              v-if="
                workspace.type &&
                ['organizations', 'contractors'].includes(
                  relationshipsStore.roleScopes[workspace.type]
                )
              "
              :id="workspace.nanoid"
              :name="workspace.team"
              :role="workspace.type"
              :tasks-number="0"
              :from-organisation="true"
              :avatar="workspace.avatar"
              class="mt-4"
              @click="showPopper = !showPopper"
            />
            <LeftMenuCardWorkspaceItem
              v-for="teamWorkspace in workspace.teams"
              :id="teamWorkspace.nanoid"
              :key="teamWorkspace.nanoid"
              :name="teamWorkspace.team"
              :role="teamWorkspace.type"
              :avatar="teamWorkspace.avatar"
              :tasks-number="0"
              @click="showPopper = !showPopper"
            />
          </div>
        </div>
      </template>
    </VDropdown>

    <div v-if="relationshipsStore.currentWorkspace" class="mt-4">
      <div class="text-[10px] font-bold uppercase text-white opacity-50">
        {{
          relationshipsStore.currentScope === "unknown"
            ? "Account"
            : relationshipsStore.scopeName(relationshipsStore.currentScope)
        }}
        Balance
      </div>
      <div class="font-mono text-[30px] font-bold leading-[38px] text-white">
        {{ $filters.currency(balance) }}
      </div>
    </div>
  </div>
</template>
