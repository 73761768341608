<script setup lang="ts">
import defaultTheme from "tailwindcss/defaultTheme";
import { useActionItemsStore } from "~/stores/api/action_items";

const emit = defineEmits(["onToggle"]);
const mobileOpen = ref(false);
const animationsEnabled = ref(true);
const route = useRoute();
const relationshipsStore = useRelationshipsStore();
const userStore = useUserStore();
const isDesktop = ref(
  window.innerWidth >= Number(defaultTheme.screens.md.replace("px", ""))
);

const navigationPath = computed(() => {
  if (!route.name) return;

  const path = route.name.toString().split("-");

  return `${path[0]}-${path[1]}`;
});

watch(
  () => route.path,
  () => {
    mobileOpen.value = false;
  }
);

type NavigationItemChildren = {
  title: string;
  to: string;
};

type NavigationItem = {
  icon: string;
  to?: string;
  title: string;
  number?: number;
  children?: NavigationItemChildren[];
};

const actionItemsStore = useActionItemsStore();

const teamNavigationItems: NavigationItem[] = reactive([
  {
    icon: "ri-dashboard-3-line",
    to: "dashboard",
    title: "Dashboard",
  },
  {
    icon: "ri-list-check-3",
    to: "tasks",
    title: "Action Items",
    number: actionItemsStore.actionItems.length,
  },
  {
    icon: "ri-team-line",
    title: "Teams",
    label: "New",
    children: [
      {
        title: "Team Dashboard",
        to: "members",
      },
      {
        title: "Edit Team",
        to: "members/edit",
      },
    ],
  },
  {
    icon: "ri-secure-payment-line",
    to: "pay",
    title: "Pay",
  },
  /*
  {
    icon: "ri-refund-line",
    to: "payroll",
    title: "Payroll",
    label: "New",
  },
  */
  {
    icon: "ri-list-view",
    title: "Payments",
    label: "New",
    children: [
      {
        title: "Accounts Payable",
        to: "payable",
      },
      /*
      {
        title: "Accounts Receivable",
        to: "invoices2",
      },
      */
    ],
  },
  {
    icon: "ri-account-pin-box-line",
    to: "history/pending",
    title: "Account History",
  },
  {
    icon: "ri-file-4-line",
    title: "Documents",
    label: "New",
    children: [
      {
        title: "All Documents",
        to: "document-list",
      },
      {
        title: "Document Builder",
        to: "document-builder/create",
      },
      {
        title: "Templates",
        to: "templates",
      },
    ],
  },
  {
    icon: "ri-bank-card-line",
    to: "deposit/blockchain-address",
    title: "Deposit",
  },
]);

const organizationNavigationItems: NavigationItem[] = reactive([
  {
    icon: "ri-dashboard-3-line",
    to: "dashboard",
    title: "Dashboard",
  },
  {
    icon: "ri-list-check-3",
    to: "tasks",
    title: "Action Items",
    number: actionItemsStore.actionItems.length,
  },
  {
    icon: "ri-team-line",
    title: "Teams",
    label: "New",
    children: [
      {
        title: "Organization Teams",
        to: "teams",
      },
      {
        title: "Add New Team",
        to: "teams/add",
      },
    ],
  },
  {
    icon: "ri-account-pin-box-line",
    to: "history/pending",
    title: "Account History",
  },
  {
    icon: "ri-file-4-line",
    title: "Documents",
    label: "New",
    children: [
      {
        title: "All Documents",
        to: "document-list",
      },
      {
        title: "Document Builder",
        to: "document-builder/create",
      },
      {
        title: "Templates",
        to: "templates",
      },
    ],
  },
  {
    icon: "ri-bank-card-line",
    to: "treasury/deposit/blockchain-address",
    title: "Deposit",
  },
  {
    icon: "ri-settings-5-line",
    to: "settings/details",
    title: "Settings",
  },
]);

const contractorNavigationItems: NavigationItem[] = reactive([
  {
    icon: "ri-dashboard-3-line",
    to: "dashboard",
    title: "Dashboard",
  },
  {
    icon: "ri-list-check",
    to: "tasks",
    title: "Action Items",
    number: actionItemsStore.actionItems.length,
  },
  {
    icon: "ri-hand-coin-line",
    to: "withdraw",
    title: "Withdraw",
    label: "New",
  },
  {
    icon: "ri-list-view",
    title: "Payments",
    label: "New",
    children: [
      {
        title: "Accounts Receivable",
        to: "receivable",
      },
      {
        title: "Create new Invoice",
        to: "receivable/flat",
      },
    ],
  },
  {
    icon: "ri-calendar-schedule-line",
    title: "Time Entries",
    label: "New",
    to: "time_entries",
  },
  {
    icon: "ri-table-view",
    to: "history/pending",
    title: "Account History",
  },
  {
    icon: "ri-file-4-line",
    title: "Documents",
    label: "New",
    children: [
      {
        title: "All Documents",
        to: "document-list",
      },
      {
        title: "Document Builder",
        to: "document-builder/create",
      },
      {
        title: "Templates",
        to: "templates",
      },
    ],
  },
]);

const employeeNavigationItems: NavigationItem[] = reactive([
  {
    icon: "ri-gauge",
    to: "dashboard",
    title: "Dashboard",
  },
  {
    icon: "ri-list-check",
    to: "tasks",
    title: "Action Items",
    number: 5,
  },
  {
    icon: "ri-hand-holding-dollar",
    to: "withdraw",
    title: "Withdraw",
    label: "New",
  },
  {
    icon: "ri-receipt",
    to: "reimbursements",
    title: "Reimbursements",
  },
  {
    icon: "ri-scroll",
    to: "pay-slips",
    title: "Pay Slips",
  },
  {
    icon: "ri-star",
    to: "benefits",
    title: "Benefits",
  },
  {
    icon: "ri-file-4-line",
    title: "Documents",
    label: "New",
    children: [
      {
        title: "All Documents",
        to: "document-list",
      },
      {
        title: "Document Builder",
        to: "document-builder/create",
      },
      {
        title: "Templates",
        to: "templates",
      },
    ],
  },
]);

const accountNavigationItems: NavigationItem[] = reactive([
  {
    icon: "ri-passport-line",
    to: "profile",
    title: "My Profile",
  },
  {
    icon: "ri-home-5-line",
    to: "address",
    title: "Address",
  },
  /*
  {
    icon: "ri-account-pin-box-line",
    to: "activity",
    title: "Activity",
  },
  */
  {
    icon: "ri-pass-valid-line",
    to: "riseid",
    title: "Rise ID",
  },
  {
    icon: "ri-police-badge-line",
    to: "security",
    title: "Security Settings",
  },
  {
    icon: "ri-settings-5-line",
    to: "settings",
    title: "Settings",
  },
]);

const navigationItems = computed(() => {
  if (relationshipsStore.currentScope === "contractors") {
    return contractorNavigationItems;
  }

  if (relationshipsStore.currentScope === "teams") {
    return teamNavigationItems;
  }

  if (relationshipsStore.currentScope === "employees") {
    return employeeNavigationItems;
  }

  if (relationshipsStore.currentScope === "organizations") {
    return organizationNavigationItems;
  }

  return accountNavigationItems.filter((e) => {
    if (e.to === "developer" && userStore.isPayee) return false;

    return true;
  });
});

watchEffect(() => {
  emit("onToggle", mobileOpen.value);
});
</script>

<template>
  <div
    class="md:hidden h-24 bg-black w-full z-10 fixed left-0 top-0 print:!hidden flex items-center px-5"
  >
    <div
      v-if="animationsEnabled"
      class="pointer-events-none absolute left-0 top-0 h-24 w-full overflow-hidden"
    >
      <BackgroundGL class="absolute top-[-100px]" />
    </div>
    <div class="cursor-pointer mr-2 z-10" @click="mobileOpen = !mobileOpen">
      <i class="ri-menu-unfold-4-line text-xl text-primary-200"></i>
    </div>
    <nuxt-link to="/" class="z-10">
      <img
        class="w-[90px]"
        src="/brand/rise-logo-white.svg"
        alt="Rise Works Inc."
      />
    </nuxt-link>
  </div>
  <transition name="slide-fade">
    <div
      v-if="isDesktop || mobileOpen"
      id="left_menu"
      class="md:w-[220px] fixed left-0 top-0 hidden md:flex h-full max-h-screen flex-col border-r border-gray-800 bg-black print:!hidden"
      :class="{
        '!h-20 md:!h-full md:overflow-visible': !mobileOpen,
        '!flex z-20 w-4/5 !border-gray-300': mobileOpen,
      }"
    >
      <div
        v-if="animationsEnabled"
        class="pointer-events-none !visible absolute left-0 top-0 h-full w-full overflow-hidden"
        :class="{ block: mobileOpen, 'hidden md:block': !mobileOpen }"
      >
        <BackgroundGL class="absolute md:left-[-300px]" />
      </div>
      <div
        class="flex-0 z-10 flex h-20 items-center justify-between p-5 md:h-fit md:px-5 md:py-[25.5px]"
        :class="{ 'h-24': mobileOpen }"
      >
        <div class="flex">
          <div
            class="!visible cursor-pointer md:hidden mr-2"
            @click="mobileOpen = !mobileOpen"
          >
            <i class="ri-menu-unfold-line text-xl text-primary-200"></i>
          </div>
          <nuxt-link to="/">
            <img
              class="w-[90px]"
              src="/brand/rise-logo-white.svg"
              alt="Rise Works Inc."
            />
          </nuxt-link>
        </div>
        <RiseDarkModeToggle
          :model-value="userStore.theme"
          @update:modelValue="(val) => userStore.setTheme(val)"
        />
      </div>
      <div
        v-if="navigationPath"
        class="!visible relative z-10 min-h-0 flex-1 overflow-x-auto px-3 pt-2 md:pt-0"
        :class="{ block: mobileOpen, 'hidden md:block': !mobileOpen }"
      >
        <LeftMenuItem
          v-for="item in navigationItems"
          :key="item.title"
          :icon="item.icon"
          :title="item.title"
          :number="item.number"
          :children="item.children"
          :to="item.to"
        />
      </div>
      <div
        class="z-10 px-3"
        :class="{ block: mobileOpen, 'hidden md:block': !mobileOpen }"
      >
        <LeftMenuCard />
        <div class="py-6">
          <LeftMenuItem
            icon="ri-questionnaire-line"
            title="Support"
            to="https://help.riseworks.io/en/"
            class="mb-1"
            target="_blank"
            variant="secondary"
          />
          <nuxt-link to="/sign-out">
            <LeftMenuItem
              icon="ri-logout-circle-r-line"
              title="Sign out"
              variant="secondary"
            />
          </nuxt-link>
        </div>
      </div>
    </div>
  </transition>
  <Teleport v-if="mobileOpen" to="body">
    <div
      class="md:hidden fixed top-0 right-0 h-screen w-full backdrop-blur-md z-10"
      @click="mobileOpen = false"
    ></div>
  </Teleport>
</template>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
